.gradient-card {
  background: linear-gradient(60deg, #81fbb8, #28c76f);
  border-radius: 8px;
  padding: 1em 2em;
  box-shadow: 0 9px 23px rgba(0, 0, 0, 0.09), 0 5px 5px rgba(0, 0, 0, 0.06);
  color: white;
  text-shadow: gray 1px 1px 5px;
  font-family: Montserrat;
}

.danger-gradient {
  background: linear-gradient(135deg, #fc9131, #fccf31);
}

.gradient-card:hover {
  box-shadow: 0 9px 23px rgba(0, 0, 0, 0.18), 0 5px 5px rgba(0, 0, 0, 0.12);
}
