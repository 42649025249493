.text-white-big {
  font-family: 'Times New Roman';
  font-size: 40px;
  color:white;
}

.border-bottom-light {
	border-bottom: 1px solid lightgray;
}

.muted-link, .muted-link:hover, .muted-link:focus, .muted-link:active {
	color: inherit;
	text-decoration: underline;
}

.bgContain {
	background-size: contain;
}

.bgContain::before {
	background-size: contain;
}

.textoDescricao {
	color: #444444;
	line-height: 21px;
	font-size: 16px;
	font-family: Arial, Helvetica, sans-serif;
}

.linha-destaque th {
	border-top: 1px solid #2ed160 !important;
	border-bottom: 1px solid #2ed160 !important;
}

.linha-destaque-final td {
	border-bottom: 1px solid #2ed160 !important;
}

.table-striped-reverse tbody tr:nth-of-type(even) {
	background-color: rgba(0, 0, 0, 0.05);
}

.table-striped-reverse thead tr {
	background-color: rgba(0, 0, 0, 0.05);
}
