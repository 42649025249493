body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.cursorPointer {
	cursor: pointer;
}
input[type='checkbox'] + label {
  display: block;
  margin: 0.2em;
  margin-left: 0;
  margin-bottom: 16px;
  cursor: pointer;
  padding: 0.2em;
  padding-left: 0;
}

input[type='checkbox'] + label > span {
  padding-right: 10px;
  padding-bottom: 3px;
}

input[type='checkbox'] {
  display: none;
}

input[type='checkbox'] + label:after {
  content: '\2714';
  border: 0.1em solid #000;
  border-radius: 0.2em;
  display: inline-block;
  width: 2em;
  height: 2em;
  text-align: center;
  padding-left: 0.2em;
  padding-top: 0.2em;
  padding-bottom: 0.3em;
  margin-right: 0.2em;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
}

input[type='checkbox'] + label:active:after {
  -webkit-transform: scale(0);
          transform: scale(0);
}

input[type='checkbox']:checked + label:after {
  background-color: MediumSeaGreen;
  border-color: MediumSeaGreen;
  color: #fff;
}

input[type='checkbox']:disabled + label:after {
  -webkit-transform: scale(1);
          transform: scale(1);
  border-color: #aaa;
}

input[type='checkbox']:checked:disabled + label:after {
  -webkit-transform: scale(1);
          transform: scale(1);
  background-color: #bfb;
  border-color: #bfb;
}

.datepicker {
  margin-left: 8px;
}

input[type='checkbox'] + label.lrc:after {
  display: none;
}

div > input[type='checkbox'] + label.lrc{
  display: block;
  margin: 0.2em;
  margin-left: 0;
  margin-bottom: 16px;
  cursor: pointer;
  padding: 0.2em;
  padding-left: 0;
}

div > input[type='checkbox'] + label.lrc{
  padding-right: 10px;
  padding-bottom: 3px;
}

div > input[type='checkbox'] + label.lrc:before{
  content: '\2714';
  border: 0.1em solid #000;
  border-radius: 0.2em;
  display: inline-block;
  width: 2em;
  height: 2em;
  text-align: center;
  padding-left: 0.2em;
  padding-top: 0.2em;
  padding-bottom: 0.3em;
  margin-right: 0.2em;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
}

div > input[type='checkbox'] + label.lrc:active:before{
  -webkit-transform: scale(0);
          transform: scale(0);
}

div > input[type='checkbox']:checked + label.lrc:before{
  background-color: MediumSeaGreen;
  border-color: MediumSeaGreen;
  color: #fff;
}

div > input[type='checkbox']:disabled + label.lrc:before{
  -webkit-transform: scale(1);
          transform: scale(1);
  border-color: #aaa;
}

div > input[type='checkbox']:checked:disabled + label.lrc:before{
  -webkit-transform: scale(1);
          transform: scale(1);
  background-color: #bfb;
  border-color: #bfb;
}

.gradient-card {
  background: linear-gradient(60deg, #81fbb8, #28c76f);
  border-radius: 8px;
  padding: 1em 2em;
  box-shadow: 0 9px 23px rgba(0, 0, 0, 0.09), 0 5px 5px rgba(0, 0, 0, 0.06);
  color: white;
  text-shadow: gray 1px 1px 5px;
  font-family: Montserrat;
}

.danger-gradient {
  background: linear-gradient(135deg, #fc9131, #fccf31);
}

.gradient-card:hover {
  box-shadow: 0 9px 23px rgba(0, 0, 0, 0.18), 0 5px 5px rgba(0, 0, 0, 0.12);
}

.text-white-big {
  font-family: 'Times New Roman';
  font-size: 40px;
  color:white;
}

.border-bottom-light {
	border-bottom: 1px solid lightgray;
}

.muted-link, .muted-link:hover, .muted-link:focus, .muted-link:active {
	color: inherit;
	text-decoration: underline;
}

.bgContain {
	background-size: contain;
}

.bgContain::before {
	background-size: contain;
}

.textoDescricao {
	color: #444444;
	line-height: 21px;
	font-size: 16px;
	font-family: Arial, Helvetica, sans-serif;
}

.linha-destaque th {
	border-top: 1px solid #2ed160 !important;
	border-bottom: 1px solid #2ed160 !important;
}

.linha-destaque-final td {
	border-bottom: 1px solid #2ed160 !important;
}

.table-striped-reverse tbody tr:nth-of-type(even) {
	background-color: rgba(0, 0, 0, 0.05);
}

.table-striped-reverse thead tr {
	background-color: rgba(0, 0, 0, 0.05);
}

