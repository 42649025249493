input[type='checkbox'] + label {
  display: block;
  margin: 0.2em;
  margin-left: 0;
  margin-bottom: 16px;
  cursor: pointer;
  padding: 0.2em;
  padding-left: 0;
}

input[type='checkbox'] + label > span {
  padding-right: 10px;
  padding-bottom: 3px;
}

input[type='checkbox'] {
  display: none;
}

input[type='checkbox'] + label:after {
  content: '\2714';
  border: 0.1em solid #000;
  border-radius: 0.2em;
  display: inline-block;
  width: 2em;
  height: 2em;
  text-align: center;
  padding-left: 0.2em;
  padding-top: 0.2em;
  padding-bottom: 0.3em;
  margin-right: 0.2em;
  vertical-align: bottom;
  color: transparent;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

input[type='checkbox'] + label:active:after {
  transform: scale(0);
}

input[type='checkbox']:checked + label:after {
  background-color: MediumSeaGreen;
  border-color: MediumSeaGreen;
  color: #fff;
}

input[type='checkbox']:disabled + label:after {
  transform: scale(1);
  border-color: #aaa;
}

input[type='checkbox']:checked:disabled + label:after {
  transform: scale(1);
  background-color: #bfb;
  border-color: #bfb;
}

.datepicker {
  margin-left: 8px;
}

input[type='checkbox'] + label.lrc:after {
  display: none;
}

div > input[type='checkbox'] + label.lrc{
  display: block;
  margin: 0.2em;
  margin-left: 0;
  margin-bottom: 16px;
  cursor: pointer;
  padding: 0.2em;
  padding-left: 0;
}

div > input[type='checkbox'] + label.lrc{
  padding-right: 10px;
  padding-bottom: 3px;
}

div > input[type='checkbox'] + label.lrc:before{
  content: '\2714';
  border: 0.1em solid #000;
  border-radius: 0.2em;
  display: inline-block;
  width: 2em;
  height: 2em;
  text-align: center;
  padding-left: 0.2em;
  padding-top: 0.2em;
  padding-bottom: 0.3em;
  margin-right: 0.2em;
  vertical-align: bottom;
  color: transparent;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

div > input[type='checkbox'] + label.lrc:active:before{
  transform: scale(0);
}

div > input[type='checkbox']:checked + label.lrc:before{
  background-color: MediumSeaGreen;
  border-color: MediumSeaGreen;
  color: #fff;
}

div > input[type='checkbox']:disabled + label.lrc:before{
  transform: scale(1);
  border-color: #aaa;
}

div > input[type='checkbox']:checked:disabled + label.lrc:before{
  transform: scale(1);
  background-color: #bfb;
  border-color: #bfb;
}
